<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">

        <b-col cols="12">
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="TableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="TableSettings.perPage"
        :current-page="TableSettings.currentPage"
        :items="myProvider"
        :fields="TableSettings.fields"
        :sort-by.sync="TableSettings.sortBy"
        :sort-desc.sync="TableSettings.sortDesc"
        :sort-direction="TableSettings.sortDirection"
        :filter="TableSettings.filter"
        :filter-included-fields="TableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ TableSettings.currentPage * TableSettings.perPage - TableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active === 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="TableSettings.perPage"
              :options="TableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="TableSettings.currentPage"
            :total-rows="TableSettings.totalRows"
            :per-page="TableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            class="text-center"
          />
        </b-col>
      </b-row>
    </b-card>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AdminDistributionService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminDistributions',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Distributions'
    }
  },

  mixins: [formatter],

  data () {
    return {
      TableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        textCenter: 'true',
        fields: [
          'index',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'distribution_code', sortable: true },
          { key: 'distribution_name', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }

    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminDistributionService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`)
        this.TableSettings.totalRows = data.total_rows
        return data.data
      } catch {
        return []
      }
    }
  }
}
</script>
